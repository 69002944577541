import webhooksApiService from '@/services/api/webhooks-api-service';
import { useHarbourStore } from '@/stores/harbour-store';
import { defineStore } from 'pinia';

export const useWebhooksStore = defineStore('webhooks-store', {
  state: () => ({
    harbourStore: useHarbourStore(),
    webhooks: [],
    isLoading: false,
    isSkeletonLoading: false,
    gridApi: null,
    gridColumnApi: null,
    editedWebhook: null,
    eventsTypeMap: {
      'agreement.viewed': 'Viewed agreement',
      'agreement.signed': 'Incrementally signed agreement',
      'agreement.completed': 'Completed agreement',
      'agreement.rejected': 'Rejected agreement',
    },
    orgsWithVerificationFeature: [
      'ORG-shiftsmart-85159b38731b44afa4cc55367c1d3450',
      'ORG13123134123123212',
      'ORG-livetest-c9619cec30ed4e26808b51bfb8bdf62c',
    ],
  }),
  getters: {
    getAllowedEventTypes: (state) => {
      // TODO: implement feature flag
      if (
        state.orgsWithVerificationFeature.includes(state.harbourStore.contextDict.organizationid)
      ) {
        return {
          ...state.eventsTypeMap,
          verification: 'Verification',
        };
      }
      return state.eventsTypeMap;
    },
    getWebhookById: (state) => (id) => {
      if (!id) return {};
      return state.webhooks.find((item) => item.id === id);
    },
  },
  actions: {
    async fetchWebhooks(params = {}) {
      this.isSkeletonLoading = true;
      this.webhooks = await webhooksApiService.listWebhooks(params);
      this.isSkeletonLoading = false;
    },
    async addWebhook(payload) {
      this.isLoading = true;
      try {
        const res = await webhooksApiService.createWebhook(payload);
        this.webhooks.push(res);
      } finally {
        this.isLoading = false;
      }
    },
    async editWebhook(id, payload) {
      this.isLoading = true;
      try {
        const res = await webhooksApiService.updateWebhook(id, payload);
        const index = this.webhooks.findIndex((item) => item.id === id);
        this.webhooks.splice(index, 1, res);
      } finally {
        this.isLoading = false;
      }
    },
    async removeWebhook(id, payload = {}) {
      this.isLoading = true;
      try {
        await webhooksApiService.deleteWebhook(id, payload);
        this.webhooks = this.webhooks.filter((item) => item.id !== id);
      } finally {
        this.isLoading = false;
      }
    },
    resetGridApi() {
      this.gridApi = null;
      this.gridColumnApi = null;
    },
  },
});
