import apiKeysApiService from '@/services/api/api-keys-api-service';
import { useHarbourStore } from '@/stores/harbour-store';
import { defineStore } from 'pinia';

export const useApiKeysStore = defineStore('api-keys-store', {
  state: () => ({
    harbourStore: useHarbourStore(),
    apiKeys: [],
    isLoading: false,
    isSkeletonLoading: false,
    gridApi: null,
    gridColumnApi: null,
  }),
  actions: {
    async fetchApiKeys(params = {}) {
      this.isSkeletonLoading = true;
      try {
        const res = await apiKeysApiService.listApiKeys(params);
        this.apiKeys = res?.items || [];
        return res;
      } finally {
        this.isSkeletonLoading = false;
      }
    },
    async addApiKey(payload) {
      this.isLoading = true;
      try {
        const res = await apiKeysApiService.createApiKey(payload);
        this.apiKeys.push(res);
        return res;
      } finally {
        this.isLoading = false;
      }
    },
    async editApiKey(id, payload) {
      this.isLoading = true;
      try {
        const res = await apiKeysApiService.updateApiKey(id, payload);
        const index = this.apiKeys.findIndex((item) => item.id === id);
        this.apiKeys.splice(index, 1, res);
        return res;
      } finally {
        this.isLoading = false;
      }
    },
    async removeApiKey(id, payload = {}) {
      this.isLoading = true;
      try {
        const res = await apiKeysApiService.deleteApiKey(id, payload);
        this.apiKeys = this.apiKeys.filter((item) => item.id !== id);
        return res;
      } finally {
        this.isLoading = false;
      }
    },
    resetGridApi() {
      this.gridApi = null;
      this.gridColumnApi = null;
    },
  },
});
